const ENV = process.env.REACT_APP_ENV;

const PROD_SERVER_BASE_URL = process.env.REACT_APP_PROD_SERVER_BASE_URL;
const DEV_SERVER_BASE_URL = process.env.REACT_APP_DEV_SERVER_BASE_URL;
const BASE_URL = ENV === "DEV" ? DEV_SERVER_BASE_URL : PROD_SERVER_BASE_URL;

//////////////
// FIREBASE//
////////////

const DEV_FIREBASE_API_KEY = process.env.REACT_APP_DEV_FIREBASE_API_KEY;
const DEV_FIREBASE_AUTH_DOMAIN = process.env.REACT_APP_DEV_FIREBASE_AUTH_DOMAIN;
const DEV_FIREBASE_PROJECT_ID = process.env.REACT_APP_DEV_FIREBASE_PROJECT_ID;
const DEV_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_DEV_FIREBASE_STORAGE_BUCKET;
const DEV_FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_DEV_FIREBASE_MESSAGING_SENDER_ID;
const DEV_FIREBASE_APP_ID = process.env.REACT_APP_DEV_FIREBASE_APP_ID;
const DEV_FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_DEV_FIREBASE_MEASUREMENT_ID;

const PROD_FIREBASE_API_KEY = process.env.REACT_APP_PROD_FIREBASE_API_KEY;
const PROD_FIREBASE_AUTH_DOMAIN =
  process.env.REACT_APP_PROD_FIREBASE_AUTH_DOMAIN;
const PROD_FIREBASE_PROJECT_ID = process.env.REACT_APP_PROD_FIREBASE_PROJECT_ID;
const PROD_FIREBASE_STORAGE_BUCKET =
  process.env.REACT_APP_PROD_FIREBASE_STORAGE_BUCKET;
const PROD_FIREBASE_MESSAGING_SENDER_ID =
  process.env.REACT_APP_PROD_FIREBASE_MESSAGING_SENDER_ID;
const PROD_FIREBASE_APP_ID = process.env.REACT_APP_PROD_FIREBASE_APP_ID;
const PROD_FIREBASE_MEASUREMENT_ID =
  process.env.REACT_APP_PROD_FIREBASE_MEASUREMENT_ID;

const FIREBASE_API_KEY =
  ENV === "DEV" ? DEV_FIREBASE_API_KEY : PROD_FIREBASE_API_KEY;
const FIREBASE_AUTH_DOMAIN =
  ENV === "DEV" ? DEV_FIREBASE_AUTH_DOMAIN : PROD_FIREBASE_AUTH_DOMAIN;
const FIREBASE_PROJECT_ID =
  ENV === "DEV" ? DEV_FIREBASE_PROJECT_ID : PROD_FIREBASE_PROJECT_ID;
const FIREBASE_STORAGE_BUCKET =
  ENV === "DEV" ? DEV_FIREBASE_STORAGE_BUCKET : PROD_FIREBASE_STORAGE_BUCKET;
const FIREBASE_MESSAGING_SENDER_ID =
  ENV === "DEV"
    ? DEV_FIREBASE_MESSAGING_SENDER_ID
    : PROD_FIREBASE_MESSAGING_SENDER_ID;
const FIREBASE_APP_ID =
  ENV === "DEV" ? DEV_FIREBASE_APP_ID : PROD_FIREBASE_APP_ID;
const FIREBASE_MEASUREMENT_ID =
  ENV === "DEV" ? DEV_FIREBASE_MEASUREMENT_ID : PROD_FIREBASE_MEASUREMENT_ID;

////////////////////
// EXCHANGE_RATE //
//////////////////

const EXCHANGE_RATE_API_KEY = process.env.REACT_APP_EXCHANGE_RATE_API_KEY;
const EXCHANGE_RATE_API_END_POINT =
  process.env.REACT_APP_EXCHANGE_RATE_API_END_POINT;

export {
  ENV,
  BASE_URL,
  FIREBASE_API_KEY,
  FIREBASE_AUTH_DOMAIN,
  FIREBASE_PROJECT_ID,
  FIREBASE_STORAGE_BUCKET,
  FIREBASE_MESSAGING_SENDER_ID,
  FIREBASE_APP_ID,
  FIREBASE_MEASUREMENT_ID,
  EXCHANGE_RATE_API_KEY,
  EXCHANGE_RATE_API_END_POINT,
};
